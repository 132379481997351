import * as THREE from "three";
import React, { useEffect } from "react";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls' 
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';

var scene, renderer
var terrain, geometry, sphere

var offsetModifier = 0.001

const Perlin = require('./perlin.js').Perlin;
var perlin = new Perlin();
var peak = 10;
var smoothing = 40;

function modifyTerrain(terrain, offset) {
  var vertices = terrain.geometry.attributes.position.array;
  for (var i = 0; i <= vertices.length; i += 3) {
      vertices[i+2] = peak * perlin.noise(
          ((-5)*-offset + vertices[i])/smoothing, 
          ((terrain.position.z) + vertices[i+1])/smoothing
      );
  }
  terrain.geometry.attributes.position.needsUpdate = true;
  terrain.geometry.computeVertexNormals();
  return {cameraPosition: terrain.geometry.attributes.position.array[1577], vehiclePosition: terrain.geometry.attributes.position.array[1589]}
}

function App() {
  useEffect(() => {
        scene = new THREE.Scene();
        
        const camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 1, 1000 );

        camera.position.z = 20
        camera.rotation.y = 10

        renderer = new THREE.WebGLRenderer();
        renderer.setSize( window.innerWidth, window.innerHeight );
        document.body.appendChild( renderer.domElement );

        /* const cylinderGeometry = new THREE.CylinderGeometry( 10, 10, 1, 128 );
        const cylinderMaterial = new THREE.MeshBasicMaterial( {color: 0x47fff6} );
        const cylinder = new THREE.Mesh( cylinderGeometry, cylinderMaterial );
        cylinder.rotation.x = - Math.PI / 2;
        cylinder.position.z = -21
        cylinder.position.y = 14
        cylinder.position.x = 0
        scene.add( cylinder ); */
        //const sphereTexture = new THREE.TextureLoader().load( 'images/2k_neptune.jpg' );


        let sphereGeometry = new THREE.SphereGeometry( 15, 32, 32 );
        let sphereMaterial = new THREE.MeshPhysicalMaterial( {color: 0xff1cff} );
        let sphere = new THREE.Mesh( sphereGeometry, sphereMaterial );
        sphere.position.z = -30
        sphere.position.x = 25
        scene.add( sphere ); 


        let side = 20;
        geometry = new THREE.PlaneGeometry(/*dont change this number*/40, 100, side, side*2.5);

        let terrainMaterial = new THREE.MeshPhysicalMaterial({color: 0x0c1d6b, wireframe: true});
        terrain = new THREE.Mesh(geometry, terrainMaterial);
        terrain.rotation.x = - Math.PI / 2;
        terrain.rotation.z = Math.PI/2;
        console.log(terrain.geometry.attributes.position.array)
        //terrain.geometry.attributes.position.array[1589] = 3
        scene.add(terrain);

        /* const axesHelper = new THREE.AxesHelper( 5 );
        scene.add( axesHelper ); */

        //Group work
        const reactorGeometry = new THREE.CylinderGeometry( 1, 1, 3, 20 );
        const shipMaterial = new THREE.MeshPhysicalMaterial( {color: 0xffffff, metalness: 1, clearcoat: 1, reflectivity: 1, roughness: 0.4} );

        const bodyGeometry = new THREE.CylinderGeometry( 2, 2, 6, 20 );

        const reactor1 = new THREE.Mesh( reactorGeometry, shipMaterial );
        reactor1.position.set( -3, -4, 0 );

        const reactor2 = new THREE.Mesh( reactorGeometry, shipMaterial );
        reactor2.position.set( 3, -4, 0 );

        const body = new THREE.Mesh( bodyGeometry, shipMaterial );

        const group = new THREE.Group();
        group.add( reactor1 );
        group.add( reactor2 );
        group.add(body);
        group.position.z = 12
        group.position.y = 4
        group.scale.set(0.3,0.3,0.3)
        group.rotation.x = - Math.PI / 2;

        scene.add( group );
        
        const getRandomParticelPos = (particleCount) => {
          const arr = new Float32Array(particleCount * 3);
          for (let i = 0; i < particleCount; i++) {
            let position = (Math.random() - 0.5) * 50;
            console.log(position)
            arr[i] = position
          }
          return arr;
        };

        
        ///////////stars
        const starsMaterial = new THREE.PointsMaterial({
          size: 0.05,
          color: 0xffffff // remove it if you want white points.
        });
        const starsGeometry = new THREE.BufferGeometry();
        starsGeometry.setAttribute(
          "position",
          new THREE.BufferAttribute(getRandomParticelPos(350), 3)
        );

        const cube = new THREE.Points(starsGeometry, starsMaterial);
        scene.add(cube);
        scene.fog = new THREE.FogExp2( 0x000000, 0.04 );


        const light = new THREE.AmbientLight( 0x404040 ); // soft white light
        scene.add( light );

        const pointLight = new THREE.PointLight( 0x00fff2, 20, 100 ); 
        pointLight.position.set( 10, 50, 50 ); 
        scene.add( pointLight );

        const pointLight2 = new THREE.PointLight( 0xff1cff, 20, 100 ); 
        pointLight2.position.set( -10, 50, 50 ); 
        scene.add( pointLight2 );

        const controls = new OrbitControls( camera, renderer.domElement );
        /* controls.autoRotate = true
        controls.autoRotateSpeed = 0.1 */
        
        

        //postprocessing
        const renderScene = new RenderPass( scene, camera );

				const bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 );
        bloomPass.exposure = 0.2;
				bloomPass.threshold = 0;
				bloomPass.strength = 2;
				bloomPass.radius = 0.4;

				let composer = new EffectComposer( renderer );
				composer.addPass( renderScene );
				composer.addPass( bloomPass );


        function animate () {
          requestAnimationFrame( animate )
          let offset = Date.now() * offsetModifier;
          let elementsPositions = modifyTerrain(terrain, offset);
          camera.position.y = (elementsPositions.cameraPosition+elementsPositions.vehiclePosition)/2+4
          cube.position.y = (elementsPositions.cameraPosition+elementsPositions.vehiclePosition)/2+4
          sphere.position.y = (elementsPositions.cameraPosition+elementsPositions.vehiclePosition)/2+15
          group.position.y = elementsPositions.vehiclePosition+3
          sphere.rotation.y -= 0.0001
          composer.render( scene, camera )
          controls.update()
        }

        animate()
      })
      
      return (
        <div>
          {/* <h1 style={{fontSize: '150px', fontFamily: 'Tahoma', color: 'white', position:'fixed', top:'20vh', marginLeft:'20px'}}>Starship</h1> */}
        </div>
      )
    
}


export default App